import React, { useEffect, useState } from "react";
import { getClinicianData } from "../../apiServices/clinicianHttpService/adminClinicianHttpService";
import { toast } from "react-toastify";
import moment from "moment";
import { editClinicianFromAdmin } from "../../apiServices/clinicianHttpService/adminClinicianHttpService";
import { useParams } from "react-router-dom";

function SlotsCalenderAdmin({ clinicianData }) {
  const [clinician, setClinician] = useState(null);
  const [activeWeek, setActiveWeek] = useState(1);
  const [weeksArray, setWeeksArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let { id } = useParams();
  useEffect(() => {
    initiateCalender();
  }, []);

  const initiateCalender = async () => {
    const currentDate = moment();
    const startDate = currentDate.clone().startOf("isoWeek");
    const endDate = startDate.clone().add(3, "months").endOf("month");
    const daysOfWeek = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    const timeArray = generateTimeSlots();
    const updatedWeeksArray = [];

    for (
      let currentDay = startDate.clone();
      currentDay.isSameOrBefore(endDate);
      currentDay.add(1, "week")
    ) {
      const weekStart = currentDay.clone();
      const weekEnd = currentDay.clone().endOf("isoWeek");
      const weekNumber = currentDay.diff(startDate, "weeks") + 1;
      const weekDays = [];

      for (
        let day = weekStart.clone();
        day.isSameOrBefore(weekEnd);
        day.add(1, "day")
      ) {
        const dayOfWeek = daysOfWeek[day.day()];
        const timeSlots = timeArray.map((timeSlot) => ({
          ...timeSlot,
          day: dayOfWeek,
        }));

        const formattedDate = day.format("DD MMM YYYY");
        weekDays.push({ date: formattedDate, day: dayOfWeek, timeSlots });
      }

      updatedWeeksArray.push({ weekNumber, days: weekDays });
    }

    await fetchClinicianData(updatedWeeksArray);
  };

  const generateTimeSlots = () => {
    return [
      {
        time1: "08:00 AM",
        time2: "09:00 AM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "08:30 AM",
        time2: "09:30 AM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "09:00 AM",
        time2: "10:00 AM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "09:30 AM",
        time2: "10:30 AM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "10:00 AM",
        time2: "11:00 AM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "10:30 AM",
        time2: "11:30 AM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "11:00 AM",
        time2: "12:00 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "11:30 AM",
        time2: "12:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "12:00 PM",
        time2: "01:00 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "12:30 PM",
        time2: "01:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "01:00 PM",
        time2: "02:00 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "01:30 PM",
        time2: "02:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "02:00 PM",
        time2: "03:00 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "02:30 PM",
        time2: "03:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "03:00 PM",
        time2: "04:00 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "03:30 PM",
        time2: "04:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "04:00 PM",
        time2: "05:00 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "04:30 PM",
        time2: "05:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "05:00 PM",
        time2: "06:00 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "05:30 PM",
        time2: "06:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "06:00 PM",
        time2: "07:00 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "06:30 PM",
        time2: "07:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "07:00 PM",
        time2: "08:00 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "07:30 PM",
        time2: "08:30 PM",
        selected: false,
        isScheduled: false,
      },
    ];
  };

  const fetchClinicianData = async (calendarData) => {
    const { data } = await getClinicianData(id);
    setIsLoading(false);

    if (!data?.error) {
      setClinician(data?.results?.clinician);

      const { all_time_slots } = data?.results?.clinician;

      let allocated_slots = data?.results?.allocated_slots;

      console.log({ all_time_slots });

      const updatedCalendar = calendarData?.map((week) => ({
        ...week,
        days: week.days.map((day) => ({
          ...day,
          timeSlots: day.timeSlots.map((timeSlot) => ({
            ...timeSlot,
            selected: isTimeSlotSelected(
              all_time_slots,
              day.date, // Use date instead of just day
              timeSlot.time1,
              timeSlot.time2
            ),
            isScheduled:
              isTimeSlotScheduled(
                allocated_slots,
                day?.date,
                timeSlot?.time1
              ) ?? false,
          })),
        })),
      }));

      setWeeksArray(updatedCalendar);
    }
  };

  // Check if a time slot is selected
  const isTimeSlotSelected = (allTimeSlots, date, time, time2) => {
    return allTimeSlots?.some((week) =>
      week.days?.some(
        (d) =>
          d.date === date &&
          d.timeSlots?.some(
            (slot) =>
              slot.time1 === time && slot.selected && slot.time2 === time2
          )
      )
    );
  };

  const isTimeSlotScheduled = (allocatedSlots, date, time) => {
    let temp = allocatedSlots?.some(
      (val) => val.date === date && val.time_slots[0] === time
    );
    console.log(temp, allocatedSlots, date, time);
    return temp;
  };

  // Handle time slot selection
  const handleTimeSlotClick = (
    weekIndex,
    dayIndex,
    timeSlotIndex,
    isSelected
  ) => {
    const updatedWeeksArray = [...weeksArray];
    updatedWeeksArray[activeWeek - 1].days[dayIndex].timeSlots[
      timeSlotIndex
    ].selected = isSelected;
    setWeeksArray(updatedWeeksArray);
  };

  console.log({ weeksArray });

  // Save selected time slots
  const onSave = async (reset) => {
    const formData = new FormData();
    let SelectedTimeArrays = [];

    weeksArray?.map((item) => {
      item?.days?.map((itm) => {
        itm?.timeSlots?.map((val) => {
          if (val.selected === true) {
            SelectedTimeArrays.push({
              date: itm?.date,
              time_slots: [val.time1, val.time2],
            });
          }
        });
      });
    });

    console.log(SelectedTimeArrays, "newSlected array");

    if (weeksArray || clinician?.time_slots) {
      reset
        ? formData.append("all_time_slots", [])
        : formData.append("all_time_slots", JSON.stringify(weeksArray));
      formData.append("time_slots", JSON.stringify(SelectedTimeArrays));
    }
    formData.append("email", clinicianData?.email);
    formData.append("name", clinicianData?.name);
    formData.append("phone_number", clinicianData?.phone_number);
    formData.append("experience", clinicianData?.experience);
    formData.append("license", clinicianData?.license);
    formData.append("bio", clinicianData?.bio);
    formData.append("expertise", JSON.stringify(clinicianData?.expertise));

    const response = await editClinicianFromAdmin(formData);

    if (!response.data.error) {
      toast.success(response.data.message);
      initiateCalender();
    } else toast.error(response.data.message);
  };

  const handleWeekNavigation = (direction) => {
    if (direction === "next" && activeWeek < weeksArray?.length) {
      setActiveWeek(activeWeek + 1);
    } else if (direction === "prev" && activeWeek > 1) {
      setActiveWeek(activeWeek - 1);
    }
  };

  if (isLoading) {
    return <div>Calender Loading...</div>;
  }

  return (
    <>
      {" "}
      <div class="row justify-content-center">
        <div class="col-md-12">
          <form class="form-design ">
            <br />
            <div className="col-12">
              <label className="fs-4 fw-bold">
                Slots Calendar{" "}
                <i
                  onClick={() => onSave(true)}
                  style={{ cursor: "pointer" }}
                  className="fa fa-refresh"
                  aria-hidden="true"
                  title="Click to Reset Calendar"
                ></i>
              </label>
              <div className="col-12 text-center border rounded reccuring_days">
                <div className="w-100">
                  <div className="slots-table">
                    <a
                      onClick={() => handleWeekNavigation("prev")}
                      title="Previous Week"
                    >
                      <i className="fa fa-arrow-left"></i>
                    </a>
                    <h1>Select Time Slots</h1>
                    <a
                      onClick={() => handleWeekNavigation("next")}
                      title="Next Week"
                    >
                      <i className="fa fa-arrow-right"></i>
                    </a>
                  </div>

                  <div className="border" key={weeksArray}>
                    {weeksArray
                      ?.filter((week) => week.weekNumber === activeWeek)
                      ?.map((week, weekIndex) => (
                        <table className="table mb-0" key={week.weekNumber}>
                          <thead>
                            <tr
                              style={{ backgroundColor: "#000", color: "#fff" }}
                            >
                              <th>Monday</th>
                              <th>Tuesday</th>
                              <th>Wednesday</th>
                              <th>Thursday</th>
                              <th>Friday</th>
                              <th>Saturday</th>
                              <th>Sunday</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {week?.days?.map((day, dayIndex) => (
                                <td key={dayIndex} className="text-center">
                                  <tr className="month_header text-center">
                                    {day.date}
                                  </tr>
                                  {day?.timeSlots?.map(
                                    (timeSlot, timeSlotIndex) => (
                                      <li
                                        key={timeSlotIndex}
                                        style={{
                                          backgroundColor: timeSlot.isScheduled
                                            ? "#5cb85c" // Scheduled time slot (High Priority)
                                            : timeSlot.selected
                                            ? "#ff8752" // Selected time slot (Only if not scheduled)
                                            : "#fff", // Default

                                          color:
                                            timeSlot.isScheduled ||
                                            timeSlot.selected
                                              ? "#fff"
                                              : "#000",
                                        }}
                                        onClick={() => {
                                          if (!timeSlot.isScheduled) {
                                            handleTimeSlotClick(
                                              weekIndex,
                                              dayIndex,
                                              timeSlotIndex,
                                              !timeSlot.selected
                                            );
                                          }
                                        }}
                                      >
                                        <time>
                                          {timeSlot.time1} - {timeSlot.time2}
                                        </time>
                                      </li>
                                    )
                                  )}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12 text-center mt-4">
                <p>
                  *After selecting recurring slots must click save button below
                </p>
                <a class="comman_btn" onClick={() => onSave()}>
                  Please Save Slots
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SlotsCalenderAdmin;
