import { gapi } from "gapi-script";
import React, { useEffect, useState } from "react";
// import { useGoogleLogin } from "react-google-login";
import { getSessionDetailss } from "../apiServices/userHttpService/adminUserHttpService";
import { useParams } from "react-router-dom";
import { AddToCalendarButton } from "add-to-calendar-button-react";

const GoogleCalenderSync = () => {
  const [details, setDetails] = useState();
  let { id } = useParams();

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    const { data } = await getSessionDetailss(id);
    if (!data.error) {
      setDetails(data.results.session);
      let info = data.results.session;
      // addCalendarEvent(
      //   info?.date,
      //   info?.user?.time_zone,
      //   info?.duration,
      //   "test",
      //   info?.name
      // );
    }
  };
  function calculateEndTime(startTime, durationInMinutes) {
    const [time, period] = startTime?.split(" ");
    let [hours, minutes] = time?.split(":").map(Number);

    if (period === "PM" && hours < 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;

    const date = new Date();
    date.setHours(hours, minutes + durationInMinutes);

    const endHours = date.getHours();
    const endMinutes = date.getMinutes();
    const endPeriod = endHours >= 12 ? "PM" : "AM";

    const formattedHours = endHours % 12 || 12;
    const formattedMinutes = endMinutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  }

  function convertTo24HourFormat(time) {
    const [timePart, period] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);

    if (period === "PM" && hours < 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  function calculateEndTime(startTime, durationInMinutes) {
    const [time, period] = startTime.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (period === "PM" && hours < 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;

    const date = new Date();
    date.setHours(hours, minutes + durationInMinutes);

    const endHours = date.getHours().toString().padStart(2, "0");
    const endMinutes = date.getMinutes().toString().padStart(2, "0");

    return `${endHours}:${endMinutes}`; // Returns 24-hour format HH:mm
  }

  // let CLIENT_ID =
  //   "815072135246-uabpflu5fqfs754dt0nnj30uggj0ulhp.apps.googleusercontent.com";
  // let API_KEY = "AIzaSyBFwPdflbLmawTFRaI2P36rfrnqXNmCbcg";
  // let DISCOVERY_DOCS = [
  //   "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  // ];
  // let SCOPES = "https://www.googleapis.com/auth/calendar.events";

  // const addCalendarEvent = (startTime, zone, dura, address, clientName) => {
  //   gapi.load("client:auth2", () => {
  //     gapi.client.init({
  //       apiKey: API_KEY,
  //       clientId: CLIENT_ID,
  //       discoveryDocs: DISCOVERY_DOCS,
  //       scope: SCOPES,
  //     });

  //     gapi.client.load("calendar", "v3");

  //     let timeZone = zone;
  //     let duration = `00:${dura}:00`;

  //     let startDate = new Date(startTime);
  //     let msDuration =
  //       (Number(duration.split(":")[0]) * 60 * 60 +
  //         Number(duration.split(":")[1]) * 60 +
  //         Number(duration.split(":")[2])) *
  //       1000;
  //     let endDate = new Date(startDate.getTime() + msDuration);
  //     let isoStartDate = new Date(
  //       startDate.getTime() - new Date().getTimezoneOffset() * 60 * 1000
  //     )
  //       .toISOString()
  //       .split(".")[0];
  //     let isoEndDate = new Date(
  //       endDate.getTime() - new Date().getTimezoneOffset() * 60 * 1000
  //     )
  //       .toISOString()
  //       .split(".")[0];

  //     gapi.auth2
  //       .getAuthInstance()
  //       .signIn()
  //       .then(() => {
  //         let event = {
  //           summary: clientName, // or event name
  //           location: address, //where it would happen
  //           start: {
  //             dateTime: isoStartDate,
  //             timeZone: timeZone,
  //           },
  //           end: {
  //             dateTime: isoEndDate,
  //             timeZone: timeZone,
  //           },
  //           recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
  //           reminders: {
  //             useDefault: false,
  //             overrides: [{ method: "popup", minutes: 20 }],
  //           },
  //           // attendees: [{ email: "naman@techgropse.com", comment: "hello" }],
  //         };

  //         gapi.client.calendar.events
  //           .list({
  //             calendarId: "primary",
  //             timeMin: new Date().toISOString(),
  //             showDeleted: false,
  //             singleEvents: true,
  //             maxResults: 10,
  //             orderBy: "startTime",
  //           })
  //           .then((response) => {
  //             const events = response.result.items;
  //             console.log("EVENTS: ", events);
  //           });

  //         let request = gapi.client.calendar.events.insert({
  //           calendarId: "primary",
  //           resource: event,
  //         });

  //         request.execute((event) => {
  //           console.log(event);
  //           window.open(event.htmlLink);
  //         });
  //       });
  //   });
  // };
  console.log(
    details?.time,
    calculateEndTime(details?.time ?? "11:00 AM", details?.duration)
  );

  return (
    <div>
      <AddToCalendarButton
        name={"Passport Journeys Session"}
        options={["Google"]}
        location="Passport Journeys App"
        startDate={details?.date?.slice(0, 10)}
        endDate={details?.date?.slice(0, 10)}
        startTime={convertTo24HourFormat(details?.time ?? "10:00 AM")}
        endTime={calculateEndTime(
          details?.time ?? "10:00 AM",
          details?.duration
        )}
        timeZone={"currentBrowser"}
        description="Passport Journeys Sessions"
      ></AddToCalendarButton>
    </div>
  );
};

export default GoogleCalenderSync;
