import React, { useState } from "react";
import { Link } from "react-router-dom";
import WebHeader from "./commonComponent/webHeader";
import WebFooter from "./commonComponent/webFooter";
import { useForm } from "react-hook-form";
import { contactUs } from "../apiServices/adminHttpService/adminLoginHttpService";
import JoinOuTeam from "./joinOurTeam";

function ContactUs() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const onSubmit = async (data) => {
    console.log(data);
    const response = await contactUs(data);
    if (!response.data.error) {
      reset();
    }
  };

  const Highlight = ({ children }) => <span className="highlight-text">{children}</span>;

  const renderWithHighlights = (text) => {
    const parts = text.split(/(<highlight>|<\/highlight>)/);
    return parts.map((part, i) => {
      if (part === '<highlight>') return null;
      if (part === '</highlight>') return null;
      if (i > 0 && parts[i-1] === '<highlight>') {
        return <Highlight key={i}>{part}</Highlight>;
      }
      return part;
    });
  };

  const faqs = [
    {
      category: "Getting Started",
      questions: [
        {
          question: "What is Passport Journeys?",
          answer: "Passport Journeys is a guided therapy app for mothers and daughters to strengthen their relationship through therapist-led sessions, structured activities, and private journaling."
        },
        {
          question: "Who is this app for?",
          answer: "The app is designed for mother-daughter pairs where both participants are at least <highlight>14 years old</highlight>. At least one person in the pair must currently <highlight>reside in Texas</highlight> to be eligible for therapy services."
        },
        {
          question: "How do I invite my mother or daughter to join?",
          answer: "After creating your account, you'll be prompted to invite your mother or daughter via email or text. They will receive a unique invite code and link to join you in the app."
        },
        {
          question: "What if my mother/daughter doesn't respond to the invite?",
          answer: "You can resend the invitation anytime from your dashboard. If they didn't receive it, they can also manually enter the code from the app's welcome screen."
        },
        {
          question: "Can I use the app without a partner?",
          answer: "No, both people must participate to unlock the full therapy experience. The app is built around the shared journey between mother and daughter."
        }
      ]
    },
    {
      category: "Therapy Program & Scheduling",
      questions: [
        {
          question: "What is included in the intake?",
          answer: "The intake process takes place within the first month and includes:\n\n- An introductory session to meet your therapist and set expectations\n- One-on-one assessment sessions for the mother and the daughter\n- A collaborative <highlight>treatment planning session</highlight> to define therapy goals\n- Therapist review and setup of your personalized monthly therapy plan\n\nThese 4 appointments must be completed within 31 days of starting the program."
        },
        {
          question: "What is included in the monthly subscription?",
          answer: "After the intake, your monthly subscription includes:\n\n- <highlight>Bi-weekly live video therapy sessions</highlight> with your therapist (2 per month)\n- <highlight>One assigned activity per month</highlight> focused intentional bonding\n- <highlight>One monthly journal prompt</highlight> for guided self-reflection\n- <highlight>One therapeutic worksheet</highlight> focused on communication growth\n- Secure in-app messaging"
        },
        {
          question: "Why can't I schedule sessions right after payment?",
          answer: "A licensed therapist is manually assigned within 2 business days after payment. You'll receive a notification once your therapist is matched, and then you can begin scheduling."
        },
        {
          question: "When will my therapist be assigned?",
          answer: "Therapist matching is completed within 48 hours of payment. You'll be notified as soon as your match is confirmed."
        },
        {
          question: "How do I reschedule a session?",
          answer: "You can reschedule a session from your Dashboard. Tap the upcoming appointment you wish to change, then select 'Reschedule' to view your therapist's available times.\n\n<highlight>Please note:</highlight>\nSessions must be rescheduled <highlight>at least 24 hours in advance</highlight> during business days (Monday–Friday) to avoid being marked as a late cancellation. Availability is not guaranteed for last-minute changes."
        },
        {
          question: "What happens if I miss a session?",
          answer: "If you miss a session without advance notice, it will be considered a <highlight>missed appointment</highlight> and may not be eligible for rescheduling. We encourage you to notify your therapist as soon as possible via the in-app chat or Help Center if an emergency arises."
        },
        {
          question: "What is the policy for late arrivals?",
          answer: "We understand that life happens. However, to respect everyone's time:\n\n- <highlight>If you are more than 15 minutes late</highlight> to a session without notice, it will be considered a <highlight>no-show</highlight> and the session will not be rescheduled.\n- If you arrive late but within 15 minutes, the session may proceed but will still end at the originally scheduled time."
        }
      ]
    },
    {
      category: "Payment & Billing",
      questions: [
        {
          question: "How much does the program cost?",
          answer: "<highlight>Month 1:</highlight> $280 (includes intake assessments and therapist onboarding)\n<highlight>Following Months:</highlight> $220/month (includes therapy sessions, activities, and progress tracking)"
        },
        {
          question: "Do you accept insurance?",
          answer: "No, Passport Journeys is a private-pay program. We do not accept insurance."
        },
        {
          question: "Is my payment refundable?",
          answer: "No, payments are non-refundable after a therapist has been assigned and services begin. If a match cannot be made, a refund may be considered."
        },
        {
          question: "Can I change my payment method?",
          answer: "Yes. Go to your profile settings > Billing & Payment to update your information."
        },
        {
          question: "Do you offer financial assistance?",
          answer: "Yes! You can <highlight>submit a financial assistance request through the Help section</highlight> in the app. Our team will review your request and respond within 5–7 business days."
        }
      ]
    },
    {
      category: "Technical Support",
      questions: [
        {
          question: "I didn't receive the invite email/code. What should I do?",
          answer: "Check your spam or promotions folder. If you still can't find it, ask your partner to resend it or contact support through the Help section."
        },
        {
          question: "I forgot my password. How do I reset it?",
          answer: "Tap 'Forgot Password' on the login screen and follow the steps to reset it via email."
        },
        {
          question: "Why am I not getting app notifications?",
          answer: "Make sure notifications are enabled for Passport Journeys in your phone's settings and within the app's notification preferences."
        },
        {
          question: "Do I need Zoom to join therapy sessions?",
          answer: "Yes. You must have the Zoom app downloaded on your phone or tablet to attend live therapy sessions."
        },
        {
          question: "Can I use Passport Journeys on a computer or laptop?",
          answer: "No. The app is currently only available on <highlight>mobile phones and tablets</highlight>. You'll need to download the app from the App Store (iOS) or Google Play Store (Android)."
        },
        {
          question: "I'm having technical issues. Who can I contact?",
          answer: "Call our support line at <highlight>(832) 779-2261</highlight> for help with login problems, app crashes, or video session issues."
        }
      ]
    },
    {
      category: "Security & Privacy",
      questions: [
        {
          question: "Is my information confidential?",
          answer: "Yes. We use HIPAA-compliant systems and encrypted storage to ensure your data is secure and confidential."
        },
        {
          question: "Can my therapist see my journal entries?",
          answer: "Yes, but your partner cannot."
        },
        {
          question: "Who has access to our sessions?",
          answer: "Only your assigned therapist has access to your session content. Individual assessments remain confidential between each person and the therapist."
        },
        {
          question: "How is my data protected?",
          answer: "We use secure servers and encryption to protect all user information and therapy content."
        }
      ]
    },
    {
      category: "Ending the Program",
      questions: [
        {
          question: "What happens after the program ends?",
          answer: "Passport Journeys has no set end date. Your therapist will help determine when your relationship goals have been met and whether continued therapy is needed."
        },
        {
          question: "Will I still have access to my past sessions, journals, and worksheets?",
          answer: "Once your subscription ends, you have read-only access for 3 months and the chat is immediately disabled."
        }
      ]
    },
    {
      category: "Emergency Help",
      questions: [
        {
          question: "What should I do in a mental health emergency?",
          answer: "Call 911 or reach out to a local mental health crisis hotline immediately. Passport Journeys is not an emergency response service."
        }
      ]
    }
  ];

  return (
    <>
      <div className="web-body">
        <WebHeader />
        <section className="contact_us">
          <div className="container">
            <div className="row mx-md-0">
              <div className="col-12 comman_heading_box">
                <span>Contact</span>
                <h2>Let's Connect</h2>
              </div>
              <div className="col-12">
                <form className="form_design row" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group col-12">
                    <p>
                      Get In Touch to Learn More About Our Mother-Daughter
                      Therapy Program!
                    </p>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form_label" htmlFor="">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      name="first_name"
                      placeholder="Enter Your First Name"
                      {...register("first_name", {
                        required: true,
                      })}
                    />
                    {errors?.first_name && (
                      <p className="form-error mt-2">This field is required</p>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form_label" htmlFor="">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      name="last_name"
                      placeholder="Enter Your Last Name"
                      {...register("last_name", {
                        required: true,
                      })}
                    />
                    {errors?.last_name && (
                      <p className="form-error mt-2">This field is required</p>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form_label" htmlFor="">
                      Email Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Email Address"
                      name="email"
                      id="email"
                      {...register("email", {
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors?.email && (
                      <p className="form-error mt-1">{errors.email.message}</p>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form_label" htmlFor="">
                      Comments/Questions
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="comments"
                      name="comments"
                      placeholder="Enter Your Comments/Questions"
                      {...register("comments", {
                        required: true,
                        maxLength: 200,
                      })}
                    />
                    {errors.comments && errors.comments.type === "required" && (
                      <p className="form-error">This field is required</p>
                    )}
                    {errors.comments && errors.comments.type === "maxLength" && (
                      <p className="form-error">
                        Username may not be longer than 200 characters
                      </p>
                    )}
                  </div>
                  <div className="form-group col-12 mt-md-4 mt-2 mb-0">
                    <button className="Buttn_comman Buttn-bg3" type="submit">
                      Submit Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="contact_team">
          <div className="container">
            <div className="row mx-md-0">
              <div className="col-12">
                <div className="contact_team_content">
                  <h2 className="fs-1">Opportunities</h2>
                  <p>
                    We are actively recruiting Licensed Clinicians who meet the
                    following criteria:{" "}
                    <ul>
                      <li>
                        Masters Level Clinicians (LCSW, LPC, LMFT) or
                        Psychologists
                      </li>
                      <li>Independently licensed in the state of Texas </li>
                      <li>
                        Versed in Systems Theory, Attachment Theory, and Trauma
                        Informed care{" "}
                      </li>
                      <li>
                        Experience working with diverse patient populations{" "}
                      </li>
                      <li>Independently licensed for at least 2 years. </li>
                      <li>
                        Additional LCDC certification or expertise a bonus{" "}
                      </li>
                    </ul>
                  </p>
                  <Link
                    className="Buttn_comman Buttn-bg1"
                    to=""
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    Join Our Team
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq_section py-5">
          <div className="container">
            <div className="row mx-md-0">
              <div className="col-12 comman_heading_box">
                <span>FAQs</span>
                <h2>Frequently Asked Questions</h2>
              </div>
              <div className="col-12">
                <div className="accordion_container">
                  {faqs.map((category, catIndex) => (
                    <div key={catIndex} className="faq_category mb-4">
                      <h3 className="faq_category_title">{category.category}</h3>
                      <div className="accordion">
                        {category.questions.map((item, index) => (
                          <div key={index} className="accordion_item">
                            <div 
                              className={`accordion_header ${activeAccordion === `${catIndex}-${index}` ? 'active' : ''}`}
                              onClick={() => toggleAccordion(`${catIndex}-${index}`)}
                            >
                              <h4>{item.question}</h4>
                              <span className="accordion_icon">
                                {activeAccordion === `${catIndex}-${index}` ? '-' : '+'}
                              </span>
                            </div>
                            {activeAccordion === `${catIndex}-${index}` && (
                              <div className="accordion_content mt-3">
                                {item.answer.split('\n').map((paragraph, i) => (
                                  <p key={i} className={paragraph.startsWith('-') ? 'mb-1' : 'mb-3'}>
                                    {renderWithHighlights(paragraph)}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <div style={{ position: "relative", top: "-4rem" }}>
          <WebFooter />
        </div>
      </div>
      <div
        className="modal fade comman_modal"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Join Our Team
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close"
              ></button>
            </div>
            <div className="modal-body py-4 px-4">
              <JoinOuTeam
                modalClose={() => document.getElementById("close").click()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;