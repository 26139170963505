import React, { useEffect, useState } from "react";
import ClinicianHeader from "./clinicianHeader";
import ClinicianSidebar from "./clinicianSidebar";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { timeArray } from "./WeekDays";
import {
  editClinician,
  getClinicianData,
  updateRecSlots,
} from "../../apiServices/clinicianPanelHttpServices/loginHttpService/clinicianLoginHttpService";
import { useNavigate } from "react-router-dom";
import SlotsCalender from "./SlotsCalender";
const options = [
  { value: "Social Worker (LCSW)", label: "Social Worker (LCSW)" },
  {
    value: "Licensed Professional Counselor (LPC)",
    label: "Licensed Professional Counselor (LPC)",
  },
  {
    value: "Licensed Marriage and Family Therapist (LMFT)",
    label: "Licensed Marriage and Family Therapist (LMFT)",
  },
  { value: "Psychologist (PsyD or PhD)", label: "Psychologist (PsyD or PhD)" },
];

function ClinicianEditProfile() {
  const [timesArray, setTimesArray] = useState(timeArray);
  const [selected, setSelected] = useState([]);
  const [clinician, setClinician] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [signImg, setSignImg] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [signature, setSignature] = useState(null);
  const [drop, setDrop] = useState(false);
  const [from, setFrom] = useState();
  const [till, setTill] = useState();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getClinicainValue();
    // addRecTimeSlots([], timesArray);
  }, []);

  const getClinicainValue = async () => {
    const { data } = await getClinicianData();
    if (!data.error) {
      setClinician(data.results.clinician);
      let defaultValue = {};
      defaultValue.name = data.results.clinician.name;
      defaultValue.phone_number = data.results.clinician.phone_number;
      defaultValue.email = data.results.clinician.email;
      defaultValue.license = data.results.clinician.license;
      defaultValue.email = data.results.clinician.email;
      defaultValue.experience = data.results.clinician.experience;
      defaultValue.email = data.results.clinician.email;
      defaultValue.bio = data.results.clinician.bio;

      reset({ ...defaultValue });
      if (data.results.clinician.profile_image) {
        setImageFile(data.results.clinician.profile_image);
      }
      if (data.results.clinician.signature) {
        setSignature(data.results.clinician.signature);
      }

      let defaultValues = [];
      for (const module of data.results.clinician.expertise) {
        const label = options.filter((item) => item.label === module);
        defaultValues.push({ label: label[0]?.label, value: module });
      }
      setSelected(defaultValues);
      setTimeSlots(data.results.clinician.all_time_slots);
      setTimesArray(
        data?.results?.clinician?.rec_time_slots?.length > 0
          ? data?.results?.clinician?.rec_time_slots
          : timeArray
      );

      let rec_slots = data.results.clinician.rec_time_slots;
      let newTemp = [];

      rec_slots?.map((item, index) => {
        item?.map((itm, val) => {
          if (itm?.selected === true) {
            newTemp.push({ time: itm?.time1, day: itm?.day });
          }
        });
      });

      console.log(newTemp, "recordSlots");
    }
  };

  const addRecTimeSlots = async (timesArrays) => {
    const response = await updateRecSlots({
      rec_time_slots: timesArrays?.length > 2 ? timesArrays : timesArray,
    });
    if (!response.data.error) {
      // getClinicainValue();
      window.location.reload(false);
    }
  };

  let handleTime = (I, i, val, item) => {
    let newFormValues = [...timesArray];
    newFormValues[I][i]["selected"] = val;
    setTimesArray(newFormValues);
  };

  const onSubmit = async (data) => {
    // data.time_slots = JSON.stringify(timeSlots);
    const formData = new FormData();
    for (const item in data) {
      formData.append(item, data[item]);
      console.log(data[item]);
    }
    
    if (selectedFile) {
      formData.append("profile_image", selectedFile, selectedFile.name);
    }

    if (signImg) {
      formData.append("signature", signImg, signImg.name);
    }

    

    const response = await editClinician(formData);

    if (!response.data.error) {
      toast.success(response.data.message);
      getClinicainValue();
      // if (timesArray) {
      //  window.location.reload()
      // }
    } else console.log(response.data.message);
  };

  const onFileSelection = (event) => {
    console.log(event);
    let file = event.target.files[0];
    if (file && file.size < 2880) {
      toast.error("Minimum File size should be 1MB.");
      return;
    } else if (file && file.size > 5242880) {
      toast.error("File size exceeded. Max size should be 5MB.");
      return;
    } else {
      // Update the state
      console.log(file);
      setImageFile(URL.createObjectURL(file));
      setSelectedFile(file);
    }
  };

  const onFileSelectionSign = (event) => {
    console.log(event);
    let file = event.target.files[0];
    if (file && file.size < 2880) {
      toast.error("Minimum File size should be 1MB.");
      return;
    } else if (file && file.size > 5242880) {
      toast.error("File size exceeded. Max size should be 5MB.");
      return;
    } else {
      setSignature(URL.createObjectURL(file));

      setSignImg(file);
    }
  };

  return (
    <>
      {" "}
      <div class="admin_main">
        <ClinicianSidebar />
        <div class="admin_main_inner">
          <ClinicianHeader />
          <div class="admin_panel_data height_adjust">
            <div class="row">
              <div class="col-12 editprofile design_outter_comman shadow">
                <div class="row comman_header justify-content-between">
                  <div class="col-auto">
                    <h2>My Profile</h2>
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-md-12">
                    <form
                      class="form-design py-4 px-3 help-support-form row align-items-start justify-content-between"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div class="form-group col-12 Edit_clinician_name">
                        <div class="row text-center justify-content-center">
                          <div class="col-auto mb-2">
                            <div class="account_profile position-relative">
                              <div class="circle">
                                <img class="profile-pic" src={imageFile} />
                              </div>
                              <div class="p-image">
                                <label for="input-file">
                                  <i class="upload-button fas fa-camera"></i>
                                </label>

                                <input
                                  class="file-upload"
                                  type="file"
                                  accept="image/*"
                                  id="input-file"
                                  onChange={(e) => onFileSelection(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-4">
                        <label for="">Clinician Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          name="name"
                          disabled
                          {...register("name", {
                            required: true,
                          })}
                        />
                        {errors?.name && (
                          <p className="form-error mt-2">
                            This field is required
                          </p>
                        )}
                      </div>
                      <div class="form-group col-4">
                        <label for="">License No.</label>
                        <input
                          type="number"
                          class="form-control"
                          id="license"
                          name="license"
                          disabled
                          {...register("license", {
                            required: true,
                          })}
                        />
                        {errors?.license && (
                          <p className="form-error mt-2">
                            This field is required
                          </p>
                        )}
                      </div>

                      <div class="form-group col-4">
                        <label for="">Mobile Number</label>
                        <input
                          type="text"
                          class="form-control"
                          id="phone_number"
                          name="phone_number"
                          disabled
                          {...register("phone_number", {
                            required: true,
                            maxLength: 10,
                            minLength: 10,
                          })}
                        />
                        {errors.phone_number &&
                          errors.phone_number.type === "required" && (
                            <p className="form-error mt-2">
                              This field is required
                            </p>
                          )}

                        {errors.phone_number &&
                          errors.phone_number.type === "maxLength" && (
                            <p className="form-error mt-2">
                              Please enter 10 digit number
                            </p>
                          )}
                        {errors.phone_number &&
                          errors.phone_number.type === "minLength" && (
                            <p className="form-error mt-2">
                              Please enter 10 digit number
                            </p>
                          )}
                      </div>

                      <div class="form-group col-4">
                        <label for="">Email</label>
                        <input
                          type="text"
                          class="form-control"
                          id="email"
                          name="email"
                          disabled
                          {...register("email", {
                            required: "This field is required",
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Invalid email address",
                            },
                          })}
                        />

                        {errors?.email && (
                          <p className="form-error mt-1">
                            {errors.email.message}
                          </p>
                        )}
                      </div>
                      <div class="form-group col-4 multiple_select_design">
                        <label for="">Expertise</label>
                        <MultiSelect
                          options={options}
                          value={selected}
                          onChange={setSelected}
                          labelledBy={"Select"}
                          isCreatable={true}
                          disabled
                        />
                      </div>

                      <div class="form-group col-4">
                        <label for="">Experience (Years)</label>
                        <input
                          type="text"
                          class="form-control"
                          id="experience"
                          name="experience"
                          disabled
                          {...register("experience", {
                            required: true,
                          })}
                        />
                        {errors?.experience && (
                          <p className="form-error mt-2">
                            This field is required
                          </p>
                        )}
                      </div>
                      <div class="form-group col-2">
                        <label for=""> Signature</label>

                        <div class="account_profile position-relative">
                          <div
                            class="square"
                            style={{
                              borderRadius: "80%",
                            }}
                          >
                            <img
                              width={100}
                              class="profile-pic"
                              src={signature}
                            />
                          </div>
                          <div class="p-image_sq">
                            <label for="upload_video2">
                              <i class="upload-button fas fa-camera"></i>
                            </label>

                            <input
                              class="file-upload"
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              name="signature"
                              id="upload_video2"
                              onChange={(e) => onFileSelectionSign(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-10 boi_box">
                        <label for="">Bio</label>
                        <textarea
                          class="form-control"
                          id="bio"
                          name="bio"
                          disabled
                          {...register("bio", {
                            required: true,
                          })}
                        ></textarea>

                        {errors?.bio && (
                          <p className="form-error mt-2">
                            This field is required
                          </p>
                        )}
                      </div>
                      <button class="comman_btn col-3 text-center" type="submit">
                        Save Profile Data
                      </button>
                      <div class="form-group col-6 text-start mt-4 d-none">
                        <a onClick={() => setDrop(!drop)} class="comman_btn">
                          Set Recurring Days
                        </a>
                      </div>

                      {drop && (
                        <div className="row justify-content-center ">
                          <div class="form-group col-12 text-end mt-4">
                            <div className="row">
                              {drop && (
                                <div className="col-12 mt-4">
                                  <a
                                    class="comman_btn mt-2 "
                                    onClick={() => setDrop(!drop)}
                                  >
                                    Close
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>

                          <div class="form-group col-10 text-center  border rounded reccuring_days">
                            <div className=" ">
                              <h1>Select Recurring Time</h1>

                              <div className="table border">
                                <table className="table mb-0 ">
                                  <thead>
                                    <tr
                                      style={{
                                        backgroundColor: "#f2f2f2",
                                      }}
                                    >
                                      <th>Sunday</th>
                                      <th>Monday</th>
                                      <th>Tuesday</th>
                                      <th>Wednesday</th>
                                      <th>Thursday</th>
                                      <th>Friday</th>
                                      <th>Saturday</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      {timesArray?.map((item, index) => (
                                        <td className=" text-center  justify-content-center ">
                                          {item?.map((itm, id) =>
                                            itm?.selected ? (
                                              <li
                                                onClick={() =>
                                                  handleTime(
                                                    index,
                                                    id,
                                                    false,
                                                    itm
                                                  )
                                                }
                                                className="selected_time"
                                              >
                                                <time>
                                                  {itm?.time1} -{itm?.time2}
                                                </time>
                                              </li>
                                            ) : (
                                              <li
                                                onClick={() =>
                                                  handleTime(
                                                    index,
                                                    id,
                                                    true,
                                                    itm
                                                  )
                                                }
                                              >
                                                <time>
                                                  {itm?.time1} -{itm?.time2}
                                                </time>
                                              </li>
                                            )
                                          )}
                                        </td>
                                      ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div class="form-group col-12 text-center mt-4">
                            <a
                              class="comman_btn"
                              onClick={() => addRecTimeSlots()}
                            >
                              Save Recurring Time
                            </a>
                          </div>
                        </div>
                      )}

                      <div class="w-100">
                        <SlotsCalender
                          slots={timeSlots}
                          rec_slots={timesArray}
                        />
                      </div>
                      <br />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClinicianEditProfile;
